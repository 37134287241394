import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import useLocalStorage from 'react-use/lib/useLocalStorage'
import styled from 'styled-components'
import FilterNav from '../../../components/FilterNav'
import Modal from '../../../components/Modal'
import PresenterModal from '../../../components/PresenterModal'
import ToggleModal from '../../../components/ToggleModal'

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;

  .section {
    > div + .padded-section {
      padding-top: 15px;
    }

    > .padded-section:last-of-type {
      padding-bottom: 0;
    }
  }

  .sessions {
    > div {
      h4 {
        margin-bottom: 10px;

        + p {
          button {
            cursor: pointer;
          }
        }
      }

      > p > span ~ button {
        cursor: pointer;
      }

      p + p {
        margin-top: 4px;
      }

      + div {
        margin-top: 40px;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    .section {
      > div + .padded-section {
        padding-top: 30px;
      }
    }

    section {
      + .padded-section {
        padding-top: 0;
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .breakout-sessions {
      > div {
        + div {
          margin-top: 60px;
        }
      }
    }
  }
`

const BreakoutSessions = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulReunionBreakoutSession(
        filter: { type: { eq: "Virtual Reunion" } }
        sort: { fields: [day, time, order, location], order: [ASC, ASC] }
      ) {
        edges {
          node {
            id
            day
            time
            title
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            location
            presenters {
              id
              name
            }
            bios {
              id
              name
              image {
                fixed(width: 170, quality: 60) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              body {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `)

  const breakoutSessions = data.allContentfulReunionBreakoutSession.edges

  const timeSlots = [
    '12:00 PM – 12:45 PM',
    '7:00 PM – 7:45 PM',
  ]

  const sessions = [
    {
      day: 'February 5th',
      date: new Date('2/5/24'),
      times: timeSlots,
    },
    {
      day: 'February 6th',
      date: new Date('2/6/24'),
      times: timeSlots,
    },
    {
      day: 'February 7th',
      date: new Date('2/7/24'),
      times: timeSlots,
    },
    {
      day: 'February 8th',
      date: new Date('2/8/24'),
      times: timeSlots,
    },
    {
      day: 'February 9th',
      date: new Date('2/9/24'),
      times: timeSlots,
    },
  ]
  const [manuallySet, setManuallySet] = useState(false)
  const [activeSessionIdx, setActiveSession] = useState(0)
  const LOCALSTORAGE_KEY = 'reunionSessionDay'
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(
    LOCALSTORAGE_KEY,
    null
  )

  useEffect(() => {
    const today = new Date()

    const urlQueryIndexMatch = sessions.findIndex((session) =>
      window.location.search.includes(`day=${session.day}`)
    )

    if (localStorageValue !== null) {
      navigate(
        `${window.location.pathname}?day=${sessions[localStorageValue].day}`,
        {
          replace: true,
        }
      )
      setActiveSession(localStorageValue)
    } else if (urlQueryIndexMatch !== -1) {
      navigate(
        `${window.location.pathname}?day=${sessions[urlQueryIndexMatch].day}`,
        {
          replace: true,
        }
      )
      setActiveSession(urlQueryIndexMatch)
    }

    sessions.forEach((session, index) => {
      if (!manuallySet) {
        if (
          `${today.getMonth()}_${today.getDate()}_${today.getFullYear()}` ===
          `${session.date.getMonth()}_${session.date.getDate()}_${session.date.getFullYear()}`
        ) {
          navigate(`${window.location.pathname}?day=${session.day}`, {
            replace: true,
          })
          setActiveSession(index)
        }
      }
    })
  }, [activeSessionIdx, localStorageValue, manuallySet])

  const handleSelectSession = (index) => {
    setManuallySet(true)
    setActiveSession(index)
    setLocalStorageValue(index)
    navigate(`${window.location.pathname}?day=${sessions[index].day}`, {
      replace: true,
    })
  }

  return (
    <Wrapper className="wrap">
      <section className="section">
        <FilterNav>
          {sessions.map((session, i) => (
            <button
              key={session.day}
              className={
                sessions[activeSessionIdx].day === session.day
                  ? 'is-active'
                  : undefined
              }
              onClick={() => handleSelectSession(i)}
            >
              {session.day}
            </button>
          ))}
        </FilterNav>
        {sessions[activeSessionIdx].times.map((time, i) => (
          <section
            key={`${sessions[activeSessionIdx].day}${i}`}
            className="padded-section sessions"
          >
            <h3 className="headline--with-line-design">
              <span>{time}</span>
            </h3>
            {breakoutSessions
              .filter(
                ({ node: breakoutSession }) =>
                  Number(breakoutSession.day) === activeSessionIdx + 1 &&
                  Number(breakoutSession.time) === i + 1
              )
              .map(({ node: breakoutSession }) => (
                <div key={breakoutSession.id}>
                  <h4 className="headline--small">{breakoutSession.title}</h4>
                  <p>
                    <span className="semi-bold-weight">
                      {breakoutSession.presenters.length > 1
                        ? 'Presenters: '
                        : 'Presenter: '}
                    </span>
                    {breakoutSession.presenters.map((presenter, index) => (
                      <ToggleModal
                        key={presenter.id}
                        toggle={(show) => (
                          <>
                            {breakoutSession.bios ? (
                              <>
                                {/*
                                <button className="accent-color" onClick={show}>
                                  {presenter.name}
                                </button>
                                */}
                                {presenter.name}
                                {breakoutSession.presenters.length > 1 &&
                                  index !== breakoutSession.presenters.length - 1
                                  ? ', '
                                  : null}
                              </>
                            ) : (
                              `${presenter.name}${breakoutSession.presenters.length > 1 &&
                                index !== breakoutSession.presenters.length - 1
                                ? ', '
                                : ''
                              }`
                            )}
                          </>
                        )}
                        content={(hide, isShown) =>
                          breakoutSession.bios ? (
                            <Modal
                              key={breakoutSession.bios[index].id}
                              hide={hide}
                              isShown={isShown}
                            >
                              <PresenterModal
                                {...breakoutSession.bios[index]}
                              />
                            </Modal>
                          ) : null
                        }
                      />
                    ))}
                  </p>
                  <p>
                    <span className="semi-bold-weight">Description: </span>
                    {breakoutSession.description.childMarkdownRemark.rawMarkdownBody}
                  </p>
                  {breakoutSession.location && (
                    <p>
                      <span className="semi-bold-weight">Where: </span>
                      {
                        <a href={breakoutSession.location} target="_blank" rel="noreferrer">
                          LOVE Website ↗
                        </a>
                      }
                    </p>
                  )}
                </div>
              ))}
          </section>
        ))}
      </section>
    </Wrapper>
  )
}

export default BreakoutSessions
